import React from "react";
import { useRouter } from "next/router";
import { isProspect } from "@/utils/type";

import SearchPage from "../../components/search/SearchPage";
import NotFound from "@/components/common/NotFound";

const Search = ({ type }) => {
    const router = useRouter();
    const { search } = router.query;

    return isProspect(type) ? <SearchPage search={search} /> : <NotFound />;
};

// can use getStaticProps without getStaticPath, so use getServerSideProps here
export async function getServerSideProps(context) {
    // get the search query from the URL
    let { search } = context.query;

    // replace the last "-" with ", ", and all other "-" with " "
    search = search.replace(/-([^-]*)$/, ", $1").replace(/-/g, " ");

    return {
        props: {
            type: process.env.NEXT_PUBLIC_TYPE,
            meta: {
                title: search + " | Purple Door",
                description: "Search for " + search + " on Purple Door",
                ogTitle: search + " | Purple Door",
                ogImage:
                    "https://cdn.purpledoorfinders.com/purpledoorfinders/logo-for-meta-data.sm.webp",
                ogType: "website",
            },
        },
    };
}

export default Search;
